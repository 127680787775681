//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

// body {
// }

html {
  scroll-behavior: smooth;
}

.top-string {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: url('../images/string-cropped.svg') repeat-x;
}

.navbar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 240px;
  background-image: linear-gradient(to bottom, black, transparent);
  &.scrolled {
    visibility: hidden;
  }
}

.navbar.custom {
  background: transparent;
  position: absolute;
  top: 35px;
  width: 100%;
  z-index: 4;
  transition: all 0.5s;
  .navbar-logo {
    display: none;
  }
  .navbar-logo-white {
    display: block;
  }
  &.scrolled {
    position: fixed;
    top:0!important;
    border-bottom: 1px solid $primary;
    .navbar-logo {
      display: block;
    }
    .navbar-logo-white {
      display: none;
    }
    .nav-link {
      color: $primary;
      &:hover {
        color: #39BA90;
      }
    }
  }
}

.nav-link {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3rem;
  text-align: center;
  color: #FFF;
  padding: 5px 10px;
  margin-left: 2.3rem;
  &:hover {
    color: #BFABCF;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 629px;
  background: url('../images/hero_image.jpg') no-repeat center center;
  background-size: cover;
  color: #FFF;
  text-align: center;
  z-index: 1;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(to bottom, black, transparent);
  z-index: 2;
}

.hero-text {
  z-index: 4;
  font-family: 'Maven Pro', sans-serif;
  text-align: center;
  h1 {
    display: inline-block;
    font-size: 2.875rem;
    line-height: 3.7375rem;
    font-weight: 700;
    text-wrap: wrap;
    br {
      display: block;
      margin-bottom: 10px;
      content: "";
    }
    span {
      display: inline;
      background: rgba(0, 0, 0, 0.6);
      padding: 5px;
      box-decoration-break: clone;
      --webkit-box-decoration-break: clone;
    }
  }
}

.info-section {
  background: #00A670;
  color: white;
  padding: 60px 20px;
  text-align: center;
  h2 {
    font-family: 'Prenton RP Pro', sans-serif;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.8rem;
    text-align: center;
  }
  p {
    font-family: 'Prenton RP Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4rem;
    text-align: center;
  }
}

.features-section {
  background-color: #40036E;
  color: #FFF;
  padding: 60px 20px 0 20px;
  h3 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
  }
}

#feature-tab {
  .nav-link {
    margin-left: 0!important;
  }
}

#features-tab-content {
  .tab-pane {
    background: #FFF;
    color: #000;
  }
}

.features-titles {
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  color: var(--Primary-Purple,#40036E);
}

.features-text {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.try-it-now-section {
  background-color: #40036E;
}

.loyol-btn {
  padding: 15px;
  background-color: #00A670;
  // height: 2.4375rem;
  // flex-shrink: 0;
  // border-radius: 0;
  color: #FFF;
  // text-align: center;
  font-family: "Poppins", sans-serif;
  // font-size: 1rem;
  // font-style: normal;
  font-weight: 600;
  // line-height: 150%;
  // text-transform: uppercase;
  &:hover{
    color: #00A670;
    background-color: #FFF;
    transition: color 0.5s, background 0.5s;
    border: 1px solid #00A670;
  }
}


// OFFERS START

.offers-cards-text {
  font-family: "Maven Pro";
  font-size: 18px;
  font-weight: 500;
  line-height: 152%;
}
.offers-title{
  font-family: "Maven Pro", sans-serif;
  line-height: 136%;
  font-size: 2.25rem;
  text-transform: uppercase;
  color: var(--Primary-Purple, #40036E);
}
.offers-content {
  background-color: var(--Primary-Green, #00A670);
  color: #fff;
}

// OFFERS END


// HOW IT WORKS START

.how-it-works-section {
  background-color: #FFF;
  padding: 49px 0;
  h1 {
    color: #40036E;
    font-family: 'Maven Pro', sans-serif;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
  }
  .slide-wrapper {
    position: relative;
    width: 100%;
    height: 450px;
    .slide-info {
      position: absolute;
      background-color: #00A670;
      top: 0;
      right: 0;
      width: 258px;
      height: 250px;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        margin-right: -20px;
      }
      .label {
        position: absolute;
        padding: 15px;
        top: 0;
        opacity: 0;
        transition: opacity 0.5s;
        &.active {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
      .label-text {
        color: #FFF;
        font-family: "Maven Pro", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 152%; /* 27.36px */
      }
      .lable-number {
        width: 71px;
        height: 71px;
        background-color: #FFE50D;
        border: 1px solid #FFE50D;
        border-radius: 50%;
        padding-top: 15px;
        color: var(--Primary-Purple, #40036E);
        text-align: center;
        font-family: "Maven Pro", sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 135%; /* 37.8px */
        text-transform: uppercase;
      }
    }
    .slide-show {
      position: relative;
      padding-top: 150px;
      .slide-panel {
        background-color: rgba(0, 0, 0, 0.1);
        height: 319px;
        z-index: 1;
        .slider {
          position: relative;
          width: 100%;
          height: 100%;
          max-width: 100%;
          margin: 0 auto;
          .slide {
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: opacity 0.5s;
            &.active {
              opacity: 1;
              transition: opacity 0.5s;
            }
            img {
              width: 229px;
              height: 226px;
              z-index: 3;
            }
            @include media-breakpoint-up(lg) {
              bottom: calc(50% - 114px);
              right: calc(50% - 113px);
            }
          }
        }
      }
      .slide-control {
        margin-top: 120px;
        .prev, .next, svg.bi-chevron-left, svg.bi-chevron-right {
          width: 64px!important;
          height: 64px;
          @include media-breakpoint-down(sm) {
            width: 42px!important;
            height: 42px;
          }
        }
        svg.bi-chevron-left, svg.bi-chevron-right {
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
}

// HOW IT WORKS END

//COSTS START

.costs-text {
  font-family: "Maven Pro";
  color: var(--Primary-Purple, #40036E);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}
.costs-example-text {
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 152%;
}

.costs-section {
  background-color: #FFF
}

.costs-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

// MEDIA QUERY TO DESKTOP SCREENS. RESIZING IT TO A SMALLER SCALE.

@media (min-width: 992px) {
  .costs-image {
    width: 70%;
  }
}

//COSTS END

//TALK TO US START

.talk-to-us-section {
  background-color: #40036E;
}

.talk-bg {
  background-color: var(--Primary-Green, #00A670);
}
.talk-bg-dark {
  background-color: var(--Primary-Purple, #40036E);
}
.talk-title {
  font-family: "Maven Pro", sans-serif;
  font-size: 36px;
  line-height: 38px;
  font-weight: 800;
  text-transform: uppercase;
  word-wrap: break-word;
  @include media-breakpoint-down(xl) {
    font-size: 28px;
    line-height: 32px;
  }
  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 28px;
  }
}
.talk-text {
  font-family: "Maven Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 152%;
}

//TALK TO US END

//Brazil Section START

.brazil-pride-section {
  position: relative;
  margin-bottom: 0; 
}

.brazil-pride-title {
  font-family: 'Maven Pro', sans-serif;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
  color: #40036E; 
  margin: 6rem 0; 
}

.brazil-pride-green-area {
  background-color: #00A670; 
  padding: 1rem 0;         
  position: relative;
}

.brazil-pride-map-box {
  background-color: #0D69FF;
  width: 350px;
  height: 350px;
  margin-top: -60px; 
  z-index: 999;       
  display: flex;
  align-items: center;
  justify-content: center;
}

.brazil-pride-map-box img {
  max-width: 80%;
  height: auto;
}

.brazil-pride-text-box {
  max-width: 600px;
  max-height: 800px;
  
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

.brazil-pride-text {
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF; 
  font-size: 16px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .brazil-pride-map-box {
    margin-top: 0;    
    width: 250px;     
    height: 250px;
    margin-bottom: 1rem;
  }
}
//Brazil Section END

//RELATIONSHIP START

.relationship-section {
  background-color: #40036E;
  color: #FFF;
}

.relationship-texts-wrapper {
  padding: 60px;
  @include media-breakpoint-down(sm) {
    padding:40px;
  }
}

.relationship-img-wrapper {
  background: url('../images/relationship-left-img.jpg') no-repeat;
  background-size: cover;
  background-position: right center;
  @include media-breakpoint-down (md) {
    height: 400px;
  }
  @include media-breakpoint-up (md) {
    height: 32.5rem;
  }
}

.relationship-large-text {
  font-family: "Maven Pro", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.06rem;
  text-transform: uppercase;
  @include media-breakpoint-down (lg) {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
}

.relationship-small-text {
  font-family: "Maven Pro", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.71rem;
  @include media-breakpoint-down (lg) {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
}

//RELATIONSHIP END

// TAKE A TEST START

.take-a-test-section {
  background-color: #40036E;
  color: #FFF;
  padding: 60px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.take-a-test-section .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.take-a-test-texts-wrapper {
  @include media-breakpoint-up (md) {
    padding: 60px;
  }
}

.take-a-test-section-bigger-text {
  color: var(--White, #FFF);
  font-family: "Maven Pro";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 136%;
  text-transform: uppercase;
}

.take-a-test-section-smaller-text {
  color: var(--White, #FFF);
  font-family: "Maven Pro";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}


.take-a-test-img-wrapper img {
  width: 270px;

  @include media-breakpoint-up (sm) {
    width: 400px!important;
  }


}
// TAKE A TEST END


// FORM START

.form-section {
  background-color: #FFF;
}

.form-section-upper-text {
  height: 5.99056rem;
  flex-shrink: 0;
  color: var(--Primary-Purple, #40036E);
  text-align: center;
  font-family: "Maven Pro";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 152%;
}

.form-section-bigger-text {
  color: var(--Primary-Purple, #40036E);
  text-align: center;
  font-family: "Maven Pro";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: 136%;
  text-transform: uppercase;
}

.custom-input {
  height: 3.375rem;
  flex-shrink: 0;
  border: 1px solid #40036E;
  border-radius: 0;
}

.custom-textarea {
  height: 100%;
  flex-shrink: 0;
  border: 1px solid #40036E;
  background: #FFF;
  resize: none;
  border-radius: 0;
}

.custom-input::placeholder, .custom-textarea::placeholder {
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.50);
}

.form-font {
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.50);
}

input[type="text"], input[type="email"], input[type="tel"], select, textarea, .form-check-input, .form-check-label {
  cursor: pointer;
}

.form-checkbox-text {
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.225rem;
}

@media (min-width: 769px) {
  .form-group:not(:last-child) {
    padding-bottom: 0.5rem;
  }

  .checkbox-wrapper {
    padding-top: 1.5rem;
  }
}

@media (max-width: 768px) {
  .form-section .container {
    padding-top: 20px;
  }

  .form-section .row {
    flex-direction: column;
  }

  .form-section .col {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-section .custom-input, .form-section .custom-textarea {
    width: 100%;
    margin-bottom: 10px;
  }

  .form-section .form-check {
    text-align: left;
    margin-bottom: 10px;
  }

  .form-section .form-section-bigger-text, .form-section .form-section-upper-text {
    font-size: 1.5rem; /* Adjust as needed */
  }

  .custom-textarea {
    width: 20.5625rem;
    height: 15.6875rem;
  }
}

//FORM END


// FOOTER START

.footer-section {
  background: #00A670;
  color: white;
  .container {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.bottom-string {
  width: 100%;
  height: 20px;
  background: url('../images/string-cropped.svg') repeat-x;
}

.footer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    color: var(--White, #FFF);
    text-align: right;
    font-family: 'Maven Pro', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

}

.footer-logo-text-font {
  width: 33.5rem;
  height: 1.1875rem;
  flex-shrink: 0;
  color: var(--White, #FFF);
  font-family: 'Maven Pro', sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer-bottom-text-container {
  padding-top: 3rem; /* Adjust this value as needed */
  padding-bottom: 5rem; /* Adjust this value as needed */
}

.footer-bottom-text {
  color: var(--White, #FFF);
  text-align: center;
  font-family: 'Maven Pro', sans-serif;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .footer-section .container {
    padding-top: 30px;
    text-align: center;
  }

  .footer-section .row {
    flex-direction: column;
  }

  .footer-section .col {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-section .footer-list li {
    text-align: center;
    font-size: 1rem;
  }

  .footer-section .footer-logo-text-font {
    width: auto;
    font-size: 1rem;
    text-align: center;
  }

  .footer-section .footer-bottom-text-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .footer-social-img {
    margin: 0 0.3em; /* Adjust the value as needed */
  }
}

// FOOTER END








@import "components/slider";
